<template>
  <BHeader />
  <div class="lg:pt-14">
    <div
      class="
        lg:pt-0
        pt-12
        lg:min-h-screen lg:h-full
        flex flex-col
        justify-center
        items-center
      "
    >
      <h3
        class="
          lg:text-4xl lg:mt-24
          text-xl
          font-bold
          text-center text-text-alternate-1
        "
      >
        Privacy Policy
      </h3>
      <p class="text-sm text-center text-text-alternate-1 mt-3">
        We Take Your Privacy Seriously!
      </p>
      <hr style="color: #d7d7d7" class="mt-8 mx-auto w-10/12" />
      <BPageLoader v-if="isLoading" />

      <div  
        v-else
        class="text-sm text-text-alternate-1 py-4 lg:w-10/12 px-12 mx-6 w-5/5 lg:mt-10"
      >
        <div v-html="data.content" class="pad"></div>
      </div>
    </div>
    <BFooterLG class="hidden z-10 bottom-0 mt-10 lg:block" />
  </div>
</template>

<script>
import { watch, onBeforeUnmount, toRefs } from 'vue';
import { useApi } from '@/cmp-functions/useApi';
import { getPrivacy } from '@/services/api';
import BFooterLG from '../components/ui/BFooterLG.vue';
import BHeader from '../components/ui/BHeader.vue';
import BPageLoader from '../components/ui/BPageLoader.vue';

export default {
  name: 'AboutUsModal',
  emits: ['close'],
  props: ['isOpen'],
  setup(props) {
    const [response, fetchPrivacy] = useApi(getPrivacy);
    fetchPrivacy();
    // console.log(response);
    onBeforeUnmount(() => {
      document.body.classList.remove('modal-open');
    });
    watch(props, ({ isOpen }) => {
      if (isOpen) document.body.classList.add('modal-open');
      else document.body.classList.remove('modal-open');
    });
    return {
      ...toRefs(response),
    };
  },
  components: { BFooterLG, BHeader, BPageLoader },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  img {
    height: 80px;
  }
  .pad{
    width: 90vw;
  }
}
</style>
